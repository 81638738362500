.nav-bar {
  align-items: center;
  background: #053225;
  display: flex;
  height: 80px;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;

  nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 80px;
    margin: 0 auto;
    width: 98%;
  }
}

.nav-logo {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 2rem;
  text-decoration: none;
}

.nav-menu {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  text-align: center;
}

.menu-icon {
  display: none;
}

.nav-item {
  border-bottom: 2px solid transparent;
  height: 80px;
  list-style: none;
  margin: 0 9px;
  text-decoration: none;
}

.nav-links {
  align-items: center;
  color: #FDB833;
  display: flex;
  height: 100%;
  padding: 0 9px;
  text-decoration: none;
  vertical-align: middle;

  .nav-button {
    background-color: transparent;
    border: none;
    color: #FDB833;
    font-size: 1.2rem;
    height: 2rem;
    padding: 0;
    width: 8rem;

    &:hover, &:focus {
      background-color: #FDB833;
      border-radius: 5px;
      color: #000;
      outline-offset: 3px;
    }
  }
}

@media screen and (max-width: 960px) {

  .nav-menu {
    display: flex;
    flex-direction: column;
    height: 50vh;
    justify-content: center;
    position: absolute;
    right: -100%;
    opacity: 1;
    overflow: hidden;
    top: 80px;
    transition: all 0.5s ease-out;
    width: 100%;
  }

  .nav-menu.active {
    background: #053225;
    height: 50vh;
    opacity: 1;
    right: 0;
    top: 80px;
    transition: all 0.6s ease-in;
    z-index: 1;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .nav-links:hover {
    transform: scale(1.05);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .nav-logo {
    font-size: 1.8rem;
    left: 0;
    position: absolute;
    transform: translate(70%, 65%);
  }

  .menu-icon {
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    font-size: 1.8rem;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-100%, 60%);
  }

  .menu-icon-home {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.8rem;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 60%);
  }

  .fa-times {
    font-size: 2rem;
  }
}