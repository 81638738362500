.home-page {
  .header-container {
    color: #fff;
    height: 100%;
    left: 20%;
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
    width: 100%;

    h1 {
      color: #97D2FB;
      font-size: 67px;
      letter-spacing: 3px;
      margin: 0 auto;
      margin-top: 23%;
    }
  }

  h2 {
    color: #fff;
    font-size: 67px;
    letter-spacing: 3px;
    margin: 0 auto;
    padding: 0;
    width: 100%;
  }

  h3 {
    color: #E9D2E3;
    font-size: 29px;
    letter-spacing: 3px;
    margin: 0 auto;
    padding: 0;
  }
}

@media screen and (max-width: 800px) {
  .home-page {
    display: flex;

    .header-container {
      left: 0;
      margin: 0 1rem;
      top: 50%;
      width: 75%;
    }

    h3 {
      font-size: 20px;
      margin: 0;
      width: 100%;
    }
  }

}