.stage-cube-cont {
  height: 80%;
  margin-left: 0;
  overflow: hidden;
  padding-top: 10%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}

.cubespinner {
  animation-name: spincube;
  animation-duration: 29s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: calc(50% - 100px);
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;

  div {
    align-items: center;
    background: rgba(88, 88, 88, 0.88);
    border: 1px solid #e9d2e3;
    border-radius: 16px;
    box-shadow: 0 0 23px 0 #e9d2e3;
    display: flex;
    font-size: 100px;
    height: 200px;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 200px;
  }
  .face1 {
    transform: translateZ(100px);
  }
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
  }
  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  }
  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

@media screen and (max-width: 820px) {
  .container.about-page {
    display: flex;
    flex-direction: column;

    .stage-cube-cont {
      height: 30vh;
      left: 0;
      margin: 0 auto;
      padding-top: 0;
      top: 60%;
      width: 90%;
    }

    .cubespinner {
      margin-left: calc(90% - 180px);
      transform-origin: 0 0 100px;
  
      div {
        border-radius: 9px;
        font-size: 70px;
        height: 80px;
        width: 80px;
      }
      .face1 {
        transform: translateZ(40px);
      }
      .face2 {
        transform: rotateY(90deg) translateZ(40px);
      }
      .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(40px);
      }
      .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(40px);
      }
      .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(40px);
      }
      .face6 {
        transform: rotateX(-90deg) translateZ(40px);
      }
    }
  }
}
