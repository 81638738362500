.flip-card-container {
  height: 300px;
  margin: 50px 0;
  width: 300px;

  .flip-card {
    background-color: transparent;
    height: 100%;

    perspective: 1000px;
    width: 100%;
  }

  .flip-card-inner {
    height: 100%;
    position: relative;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    width: 100%;
  }

  .flip-card-image {
    background-color: #fff;
    background-size: center;
    max-width: 360px;
    max-height: 360px;
    width: 100%;
  }

  .flip-card:hover .flip-card-inner,
  .flip-card:focus-within .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    backface-visibility: hidden;
    height: 100%;
    position: absolute;
    -webkit-backface-visibility: hidden; /* Safari */
    width: 100%;
  }

  .flip-card-back {
    align-items: center;
    background-color: #e9d2e3;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transform: rotateY(180deg);

    a {
      color: #000;
      text-decoration: none;
    }

    .project-link {
      border: solid 1px #000;
      border-radius: 3px;
      padding: 3px 9px;
      width: min-content;

      &:hover, &:focus {
        background-color: #833f6d;
        border: solid 1px #833f6d;
        color: #fff;
      }
    }

    .github-link {
      font-size: 30px;

      &:hover, &:focus {
        color: #833f6d;
      }
    }

    p {
      height: auto;
      width: 90%;
    }
  }
  .flip-card-front {
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    color: black;
  }
}
