.footer-container {
  background-color: #053225;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  z-index: 999;
  position: sticky;
}

/* Social Icons */
.social-icon-link {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 24px;

  &:hover, &:focus {
    color: #FDB833;
  }
}

.social-media {
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1300px;
  margin: 0 auto;
  font-weight: 700;
}

.social-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 860px) {

  .social-media-wrap {
    flex-direction: column;
  }
}
