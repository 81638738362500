* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100vh;
  width: 100vw;
}

body {
  background-color: #053225;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: Helvetica, sans-serif;
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

.App {
  flex: 1;
}
