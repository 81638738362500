.container.projects-page {
  align-items: center;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: minmax(200px, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-auto-flow: dense;
  height: 100%;
  margin-bottom: 50px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;

  .text-zone {
    grid-area: 1 / 1 / 3 / 2;
    margin-left: -150px;
    transform: rotate(-90deg);
    width: 400px;

    h1 {
      color: #C6EBBE;
      font-size: 89px;
      font-weight: 700;
    }
  }

  .project-cards {
    align-items: center;
    display: grid;
    grid-area: 1 / 2 / 3 / 4;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));
    height: 100%;
    justify-items: center;
    min-height: fit-content;
    width: 100%;

    .project-intro {
      padding: 18px;
    }
  }
}

@media screen and (max-width: 820px) {
  .container.projects-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;

    .text-zone {
      margin: 0 1rem;
      transform: rotate(0deg);
      width: 95%;
    }

    .project-cards {
      display: flex;
      flex-direction: column;
    }
  }
}
