@import 'animate.css';

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.container {
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
  height: 60vw;
  margin: 0 auto;
  min-height: 450px;
  opacity: 0;
  position: relative;
  transform-style: preserve-3d;
  width: 80%;
  will-change: contents;
  z-index: 1;
}

.container.contact-page,
.container.about-page,
.container.home-page {
  height: 100%;

  h1 {
    color: #C6EBBE;
    font-size: 53px;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 0;
    position: relative;
  }

  .my-name {
    color: #fff;
    margin-bottom: 0;
  }

  p {
    animation: pulse 1s;
    color: #fff;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 600;
    min-width: fit-content;

    &:nth-of-type(1) {
      animation-delay: 1.1s
    }

    &:nth-of-type(2) {
      animation-delay: 1.2s
    }

    &:nth-of-type(3) {
      animation-delay: 1.3s
    }
  }

  .text-zone {
    border-radius: 5px;
    display: table-cell;
    max-height: 80%;
    padding: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
    width: 50%;
  }

  .text-animate-hover {
    &:hover {
      color: #FDB833;
    }
  }

  .contact-text-zone {
    border-radius: 5px;
    display: table-cell;
    max-height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
  }
}

@media screen and (max-width: 820px) {
  .container.contact-page,
  .container.about-page,
  .container.home-page {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 0;

    .text-zone {
      left: 0;
      margin: 0 auto;
      padding: 0 0.5rem;
      right: 0;
      width: 85%;
    }
  }

  .container.contact-page {
    margin-top: 10%;
    width: 100%;
  }
}
